.welcome {
    padding: 20px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
}

.welcome h1 {
    color: #333;
}

.welcome p {
    color: #666;
}