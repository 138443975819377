/* Final Message Styles */
.final-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
    background-color: #f9f7f7;
  }
  
  .final-message h1 {
    font-size: 3rem;
    color: #ff6f61;
  }
  
  .final-message p {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .final-message button {
    font-size: 1.2rem;
    padding: 12px 30px;
    background-color: #ff6f61;
    border-radius: 10px;
  }
  