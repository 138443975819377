.app {
    background-image: url('https://cdn-europe1.lanmedia.fr/var/europe1/storage/images/europe1/sport/f1-max-verstappen-remporte-le-gp-du-japon-red-bull-titre-champion-du-monde-des-constructeurs-4205303/60627112-1-fre-FR/F1-Max-Verstappen-remporte-le-GP-du-Japon-Red-Bull-titre-champion-du-monde-des-constructeurs.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
  }

.quiz {

    font-family: Arial, sans-serif;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 2px solid #00205b;
    border-radius: 10px;
    background-color: #fff;
  }
  
  .question-section {
    margin-bottom: 20px;
  }
  
  .question-count {
    font-size: 18px;
    font-weight: bold;
    color: #00205b; /* Bleu Red Bull */
  }
  
  .question-text {
    font-size: 16px;
    color: #444;
    margin-top: 10px;
  }
  
  .answer-section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  
  .quiz button {
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #00205b; /* Bleu Red Bull */
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .quiz button:hover {
    background-color: #0056b3; /* Bleu plus foncé pour l'effet hover */
  }
  
  .score-section {
    font-size: 20px;
    font-weight: bold;
    color: #00205b; /* Bleu Red Bull */
    text-align: center;
    margin-top: 20px;
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
textarea {
    width: 100%;
    height: 100px; /* Ajustez la hauteur selon vos besoins */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical; /* Permet de redimensionner verticalement */
  }
  .quiz input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  textarea:focus {
    outline: none;
    border-color: #007bff; /* Couleur de bordure lorsque le textarea est en focus */
  }

.popup-content {
    text-align: center;
}

.popup-buttons {
    margin-top: 20px;
}

.popup-button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.popup-button:hover {
    background-color: #0056b3;
}