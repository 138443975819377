/* NoteItem.css */

.note-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  width: 200px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 10px;
  cursor: pointer;
  position: relative;
}

.note-item--expanded {
  width: 80%;
  height: auto;
  max-width: 800px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: #fff;
  padding: 40px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.note-item h3 {
  margin: 0;
  font-size: 1.2em;
}

.note-item p {
  margin: 10px 0;
  font-size: 1em;
}

.note-item__delete-button,
.note-item__save-button {
  padding: 5px 10px;
  cursor: pointer;
  background-color: #FF5733;
  color: white;
  border: none;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.note-item__edit input,
.note-item__edit textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.note-item__title-input {
  font-size: 1.2em;
  margin-bottom: 20px;
}
