.ErrorPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
}

.ErrorPage h1 {
    color: #ff0000;
    font-size: 3em;
}

.ErrorPage p {
    font-size: 1.5em;
}