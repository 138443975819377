/* Chronometer.css */

/* Styles pour les petits écrans (Mobile First) */
.chronometer {
    margin: 10px;
    text-align: center;
  }
  
  .chronometer h1 {
    font-size: 32px;
    margin: 10px 0;
  }
  
  .chronometer button {
    font-size: 16px;
    margin: 5px;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .chronometer ul {
    list-style-type: none;
    padding: 0;
  }
  
  .chronometer li {
    font-size: 14px;
    margin: 5px 0;
  }
  
  /* Styles pour les écrans moyens */
  @media (min-width: 600px) {
    .chronometer h1 {
      font-size: 40px;
    }
  
    .chronometer button {
      font-size: 18px;
      padding: 10px 20px;
    }
  
    .chronometer li {
      font-size: 16px;
    }
  }
  
  /* Styles pour les grands écrans */
  @media (min-width: 1024px) {
    .chronometer h1 {
      font-size: 48px;
    }
  
    .chronometer button {
      font-size: 20px;
      padding: 12px 24px;
    }
  
    .chronometer li {
      font-size: 18px;
    }
  }
  



  /* Clock.css */

.clock-container {
    text-align: center;
    margin: 20px;
  }
  
  .clock-title {
    font-size: 24px;
  }
  
  .clock-time {
    font-size: 36px;
  }
  
  /* Styles pour les écrans moyens */
  @media (min-width: 600px) {
    .clock-title {
      font-size: 28px;
    }
  
    .clock-time {
      font-size: 48px;
    }
  }
  
  /* Styles pour les grands écrans */
  @media (min-width: 1024px) {
    .clock-title {
      font-size: 32px;
    }
  
    .clock-time {
      font-size: 64px;
    }
  }

/* QuoteInput.css */

/* Styles pour les petits écrans (Mobile First) */
.quote-input-container {
  text-align: center;
  max-width: 800px;
  margin: auto;
}

.quote-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.quote-textarea {
  width: 100%;
  min-height: 100px;
  height: auto;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.quote-button {
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
}

/* Styles pour les écrans moyens */
@media (min-width: 600px) {
  .quote-title {
    font-size: 28px;
  }

  .quote-textarea {
    font-size: 18px;
  }

  .quote-button {
    font-size: 18px;
  }
}

/* Styles pour les grands écrans */
@media (min-width: 1024px) {
  .quote-title {
    font-size: 32px;
  }

  .quote-textarea {
    font-size: 20px;
  }

  .quote-button {
    font-size: 20px;
  }
}