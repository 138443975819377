.appNote {
  background-image: url('https://img.freepik.com/premium-photo/abstract-background-design-images-wallpaper-ai-generated_643360-56886.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
}
  
  h1 {
    color: white;
    font-size: 2em;
    margin-bottom: 20px;
  }