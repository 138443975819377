/* Gallery Page Styles */
.gallery-page {
    padding: 50px 20px;
    background-color: #f0f0f0;
  }
  
  .gallery h2 {
    font-size: 2.5rem;
    color: #333;
  }
  
  .memory-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    max-width: 300px;
  }
  
  .memory-card img {
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
  }
  
  .memory-card h2 {
    font-size: 1.5rem;
    margin: 15px 0;
  }
  
  .memory-card p {
    font-size: 1rem;
    color: #666;
    margin: 10px 0;
  }
  
  .memory-card:hover {
    transform: scale(1.05);
  }
  