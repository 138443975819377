/* NoteInput.css */

.note-input {
    margin-bottom: 20px;
  }
  
  .note-input input,
  .note-input textarea {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .note-input button {
    padding: 10px 20px;
    cursor: pointer;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
  }