.App {
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  header {
    background-color: #282c34;
    padding: 20px;
    color: white;
  }
  
  nav ul {
    list-style: none;
    padding: 0;
  }
  
  nav ul li {
    display: inline;
    margin: 0 10px;
  }
  
  nav ul li a {
    text-decoration: none;
    color: #61dafb;
  }
  
  nav ul li a:hover {
    text-decoration: underline;
  }
  
  table {
    margin: 20px auto;
    width: 80%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  form {
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  
  input, select, button {
    margin: 5px 0;
    padding: 10px;
    font-size: 16px;
  }
  
  button {
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  h2, h3 {
    color: #333;
  }