/* Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    background-color: #f7f7f7;
    color: #333;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  button {
    padding: 10px 20px;
    background-color: #ff6f61;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #ff4a3b;
  }
  
  /* Layout */
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .cover-photo {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: 20px;
  }
  
  h1, h2, p {
    margin-bottom: 20px;
  }
  
  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .memory-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .memory-card:hover {
    transform: translateY(-5px);
  }
  
  .memory-photo {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .memory-date {
    font-size: 14px;
    color: #999;
    margin-top: 10px;
  }
  
  /* Home Page Styles */
.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #e1f7f7;
  }
  
  .home-page h1 {
    font-size: 3rem;
    color: #333;
  }
  
  .home-page p {
    font-size: 1.2rem;
    margin: 20px 0;
  }
  
  .home-page button {
    font-size: 1.1rem;
    padding: 10px 30px;
    background-color: #ff6f61;
    border-radius: 10px;
  }
  
  .home-page img {
    margin-top: 30px;
    max-width: 80%;
    border-radius: 20px;
  }
  